import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import ManagePayments from '../ManagePayments';
import * as apiUtils from '../../utils/apiUtils';
import useProfile from '../../utils/useProfile';
import PaymentComponent from '../PaymentComponent'
import CardDetails from '../CardDetails';
import './AccountPage.css'
import useToken from '../../utils/useToken';
import { toast } from 'react-toastify';
import ConfirmationPopup from './ConfirmationPopup';
function AccountPage() {
  const [planId, setPlanId] = useState('');
  const { profile } = useProfile();
  const [userSetting, setUserSetting] = useState();
  const [subscribeBtnStatus, setSubscribeBtnStatus] = useState(true);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [planPrice, setPlanPrice] = useState('')
  const { setToken } = useToken();
  const { setProfile } = useProfile();

  const getUserSettings = async () => {
    try {
      const data = await apiUtils.get('/api/user/user_setting');
      console.log('API Response:', data);
      setUserSetting(data)
    } catch (error) {
      console.error('getUserSettings.error', error)
    }
  }
  useEffect(() => {
    getUserSettings();
  }, []);

  useEffect(() => {
    if (userSetting?.customer?.default_card && Object.keys(userSetting?.customer?.default_card).length > 0) {
      setSubscribeBtnStatus(false);
    }
  }, [userSetting]);

  const handleResetPassword = async () => {
    // Call reset password API
    try {
      const email = profile?.email;
      const data = await apiUtils.post('/api/auth/password/reset/', { email });
      if (data) {
        toast.success("A reset password link has been sent to your email.");
        setToken('')
        setProfile('')
        setTimeout(() => {
          window.location.href = '/logout';
        }, 2000); // delay of 2 seconds to show taost. logout user if successfully sent the message
      }
      // Close the popup
      setShowResetPopup(false);
    } catch (error) {
      console.error('Error during reset password process', error);
      setShowResetPopup(false);
    }
  };

  return (
    <div >
      <Menu isPlanActive={true} />

      <div className="account-page">
        <div className='account-page-user-info'>
          <p>Hello, <span>{profile?.first_name + " " + profile?.last_name}</span> </p>
          <p>Email: <span>{profile?.email}</span></p>
          <p className="reset-password-link">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowResetPopup(true);
              }}
            >
              Reset Password
            </a>
          </p>
        </div>
        {userSetting?.customer?.default_card && Object.keys(userSetting?.customer?.default_card).length > 0 && <CardDetails card={userSetting?.customer?.default_card} />}
        {!planId && <ManagePayments
          setPlanId={setPlanId}
          profile={profile}
          userSetting={userSetting}
          displaySubscribeBtn={subscribeBtnStatus}
          setPlanPrice={setPlanPrice}
        />
        }
        {planId && planPrice !== 0 && (
          <PaymentComponent planId={planId} />
        )}
        {showResetPopup && (
          <ConfirmationPopup
            message="Are you sure you want to reset your password?"
            onConfirm={handleResetPassword}
            onCancel={() => setShowResetPopup(false)}
          />
        )}
      </div>
    </div>
  );
}

export default AccountPage;
