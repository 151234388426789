import React, { useEffect, useState } from 'react';
import Menu from '../Menu'
import ManagePayments from '../ManagePayments';
import * as apiUtils from '../../utils/apiUtils';
import PaymentComponent from '../PaymentComponent';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CheckoutForm = () => {
    const [planId, setPlanId] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const navigate = useNavigate();
    const processPaymentToken = async (planId) => {
        try {
            const data = await apiUtils.post('/api/user/subscribe', {
                plan_id: planId
            });
            return data
        } catch (error) {
            console.error('API Error:', error);
            return error
        }
    }
    useEffect(() => {
        if (!planId || planPrice !== 0) return;
        if (planPrice === 0) {
            const handleSubscription = async () => {
                try {
                    const data = await processPaymentToken(planId);
                    toast.success(data.status);
                    navigate('/dashboard'); // Redirect to the dashboard
                } catch (error) {
                    console.error('Error processing free tier subscription:', error);
                }
            };
            handleSubscription();
        }
    }, [planId, navigate]);

    return (
        <>
            <Menu isPlanActive={false} />
            <div className='notes-list'>
                {!planId && (
                    <ManagePayments setPlanId={setPlanId} setPlanPrice={setPlanPrice} displaySubscribeBtn={true} />
                )}
                {planId && planPrice !== 0 && (
                    <PaymentComponent planId={planId} />
                )}
            </div>
        </>
    );
};

export default CheckoutForm;