import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import * as apiUtils from '../../utils/apiUtils';
import { toast } from 'react-toastify';
import './PaymentForm.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
const PaymentForm = (props) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const processPaymentToken = async (token) => {
        try {
            const data = await apiUtils.post('/api/user/subscribe', {
                plan_id: props.planId
                , stripe_token: token
            });
            return data
        } catch (error) {
            console.error('API Error:', error);
            return error
        }
    }
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        try {
            if (!stripe || !elements) {
                return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            const { error, token } = await stripe.createToken(cardNumberElement);

            if (error) {
                console.log('[error]', error);
            } else {
                console.log('[token]', token);
                await processPaymentToken(token.id)
                toast.success("Payment processed Successfully.");
                // navigate('/account');
                window.location.replace('/account');
            }
        } catch (error) {
            console.log('handleSubmit.error', error)
        }
        finally {
            setLoading(false)
        }
    };

    return (
        <>
            <div className="payment-container">
                <h2>Payment Information</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Card Number
                        <CardNumberElement disabled={true} className="stripe-input" />
                    </label>
                    <label>
                        Expiration Date
                        <CardExpiryElement className="stripe-input" />
                    </label>
                    <label>
                        CVC
                        <CardCvcElement className="stripe-input" />
                    </label>
                    {loading ? (
                        <div>Processing Payment...</div>
                    ) : <button className="payment-component-btn" type="submit" disabled={!stripe}>Pay</button>}
                </form>
            </div>
        </>)
}
    ;

export default PaymentForm;
