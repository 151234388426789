import React, { useState, useEffect } from 'react';
import * as apiUtils from '../../utils/apiUtils';
import ClientInfo from '../ClientInfo';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { toast } from 'react-toastify';
import './AudioRecorder.css';
import Loader from '../Loader';
import useWakeLock from "react-use-wake-lock";
import { useNavigate } from 'react-router-dom';

const AudioRecorder = () => {
    const [loading, setLoading] = useState(false);
    const { isSupported, isLocked, request, release } = useWakeLock();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedClientID, setSelectedClientID] = useState('');
    const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [timer, setTimer] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    const addNewClient = () => {
        togglePopup()
    };
    const getClientsList = async () => {
        try {
            const data = await apiUtils.get('/api/notes/clients');
            console.log('API Response:', data);
            setClients(data.results)
        } catch (error) {
            console.error('getUserSettings.error', error)
        }
    }
    useEffect(() => {
        getClientsList()
    }, [showPopup]);
    useEffect(()=>{
        if (mediaRecorder) {
            startRecording()
        }
    },[mediaRecorder])
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                setMediaRecorder(recorder);
                recorder.ondataavailable = (event) => {
                    setAudioChunks(prev => [...prev, event.data]);
                    uploadAudio([event.data])
                };
            })
            .catch(error=>{
                toast.error("Permission denied")
                console.log('error', error)
            })
    }, []);

    const handleClientChange = (args) => {
        setSelectedClient(`${args.first_name} ${args.last_name}`);
        setSelectedClientID(args.id);
    };

    const startRecording = () => {
        request()
        if (mediaRecorder && !recording) {
            setAudioChunks([]);
            mediaRecorder.start();
            setRecording(true);
            setPaused(false);
            const id = setInterval(() => setTimer(prev => prev + 1), 1000);
            setIntervalId(id);
        }
    };

    const pauseRecording = () => {
        if (mediaRecorder && recording && !paused) {
            mediaRecorder.pause();
            setPaused(true);
            clearInterval(intervalId);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && paused) {
            mediaRecorder.resume();
            setPaused(false);
            const id = setInterval(() => setTimer(prev => prev + 1), 1000);
            setIntervalId(id);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && recording) {
            mediaRecorder.stop();
            setRecording(false);
            setPaused(false);
            clearInterval(intervalId);
            setTimer(0);
        }
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };
    const getPreSignedURL = async (e) => {
        try {
            const data = await apiUtils.get('/api/notes/generate_presigned_url');
            return data
        } catch (error) {
            console.log('handleSubmit.error', error)
        }
    };
    const uploadAudio = async (data) => {
        setLoading(true)
        const preSignedObj = await getPreSignedURL()
        if (data.length > 0) {
            try {
                if (preSignedObj && preSignedObj.presigned_url) {
                    const headers = new Headers();
                    headers.append("x-ms-blob-type", "BlockBlob");
                    headers.append("Content-Type", "audio/wave");
                    const audioBlob = new Blob(data, { type: 'audio/wav' });
                    const response = await fetch(preSignedObj.presigned_url, {
                        method: 'PUT',
                        body: audioBlob,
                        headers: headers,
                    });
                    if (response.ok) {
                        console.log('Upload successful');
                        const data = await apiUtils.post('/api/notes/', {
                            "client": selectedClientID,
                            "file_id": preSignedObj.file_id
                        });
                        toast.success("Note Created Successfully.");
                        if (data) {
                            navigate('/create-note')
                        }
                        release()
                    } else {
                        console.error('Upload failed');
                    }
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setLoading(false)
            }
        }
        setLoading(false)
    };
    return (

<>
{
        loading ? (
            <Loader />
        ) : (        <div className="audio-recorder-container">


        <UncontrolledDropdown
            className="me-2"
            direction="down"
        >
            <DropdownToggle
                caret
                color="primary"
            >
                {selectedClient ? selectedClient : 'Select Client'}
            </DropdownToggle>
            <DropdownMenu>

                {clients.map((client) => {
                    return <DropdownItem onClick={() => handleClientChange(client)} >
                        {client.first_name} {client.last_name}
                    </DropdownItem>
                })}
                <DropdownItem divider />
                <DropdownItem onClick={addNewClient}>
                    Add New Client
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>

        <div className="timer">{formatTime(timer)}</div>

        <div className="button-container">
            {!recording && <button className="audio-recorder-btn" onClick={startRecording}>Start <i style={{ marginLeft: '20px' }} className="fas fa-microphone"></i></button>}
            {recording && !paused && <button className="audio-recorder-btn" onClick={pauseRecording}>Pause <i style={{ marginLeft: '20px' }} className="fas fa-pause"></i></button>}
            {paused && <button className="audio-recorder-btn" onClick={resumeRecording}>Resume <i style={{ marginLeft: '20px' }} className="fas fa-play"></i></button>}
            <button className="audio-recorder-btn" onClick={stopRecording} disabled={!recording}>Stop <i style={{ marginLeft: '20px' }} className="fas fa-stop"></i></button>
        </div>

        {/* {audioChunks.length > 0 && <button className="audio-recorder-btn" onClick={uploadAudio}>Upload <i style={{ marginLeft: '20px' }} className="fas fa-upload"></i></button>} */}
        {showPopup && (
            <div className="popup-overlay">
                <div className="popup">
                    <ClientInfo togglePopup={togglePopup} />
                </div>
            </div>
        )}






        {/* <div>
  <h3>Screen Wake Lock API supported: {isSupported ? "Yes" : "No"}</h3>
  <h3>Locked: {`${isLocked ? "Yes" : "No"}`}</h3>
  <button type="button" onClick={() => (isLocked ? release() : request())}>
    {isLocked ? "Release" : "Request"}
  </button>
</div> */}
    </div>
)}

    </>
    );
};

export default AudioRecorder;
