import React, { useState, useEffect } from 'react';
import './SubscriptionSelector.css';
import Loader from '../Loader';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as apiUtils from '../../utils/apiUtils';

const SubscriptionSelector = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();
    const [subscriptionOptions, setSubscriptionOptions] = useState([]);

    const getSubscriptionList = async () => {
        setIsLoading(true);
        try {
            const data = await apiUtils.get('/api/user/products_price');
            console.log('API Response:', data);
            setSubscriptionOptions(data);
            const defaultSelectedOption = data.find(obj => obj.default_price.djstripe_id === props?.userSetting?.subscription?.default_price?.djstripe_id);
            setSelectedOption(defaultSelectedOption?.default_price?.id);
        } catch (error) {
            console.error('Error fetching subscription options:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getSubscriptionList();
    }, [props.userSetting, props.setPlanPrice]);

    const handleOptionChange = (e) => {
        const selectedId = e.target.value;
        const selectedOption = subscriptionOptions.find(option => option.default_price.id === selectedId);

        if (selectedOption) {
            setSelectedOption(selectedId);
            props.setPlanPrice(selectedOption?.default_price?.unit_amount);
        }
    };
    const updateSubscription = async () => {
        setIsLoading(true)
        try {
            const data = await apiUtils.post('/api/user/update_subscription_plan', { plan_id: selectedOption });
            console.log('data', data)
            toast.success(data.status);
        } catch (error) {
            toast.error(error.status);
            console.error('Error fetching subscription options:', error);
        } finally {
            setIsLoading(false)
        }
        navigate('/account')
    };
    const handleSubmit = async () => {
        // Check if the user is already subscribed to the free tier
        if (!props.userSetting?.customer?.default_card || Object.keys(props.userSetting?.customer?.default_card).length === 0) {
            if (
                props.userSetting?.subscription?.default_price?.id === selectedOption
            ) {
                toast.error("You are already subscribed to the free plan.");
                return;
            }
        }
    
        if (props.displaySubscribeBtn) {
            props.setPlanId(selectedOption);
        } else {
            await updateSubscription();
        }
    };
    

    return (

        <div className="subscription-container">
            <div className="profileName">Choose Your Subscription Plan</div>
            {isLoading ? (<div className='loader-height'><Loader /></div>) : (<>
                <div className="options-container">
                    {subscriptionOptions.map(option => (
                        <div key={option.id} className="option">
                            <Row xs="12">
                                <Col xs="2">
                                    <input type="radio" value={option?.default_price?.id}
                                        checked={selectedOption === option?.default_price?.id} onChange={handleOptionChange} />
                                </Col>
                                <Col xs="10">
                                    <div className="option-description">
                                        <div className="option-name">{option.name} - ${parseFloat(option?.default_price?.unit_amount / 100).toFixed(2)}</div>
                                        {/* Display the subscription type's explanatory copy */}
                                        <div className="option-sessions-info">{option.description}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
                {<button className="subscribe-btn" onClick={handleSubmit}>Subscribe</button>}
            </>)}
        </div>
    );
};

export default SubscriptionSelector;
